/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import { Link } from 'react-router-dom';

import OverflowTooltip from './OverflowTooltip';

export default function NonSelectionList({
  options,
  height = 300,
  labelField = 'label',
  groupingField,
  divider = true,
}) {
  const optionsDisplay = useMemo(() => {
    if (groupingField) {
      const groupedOptionsArray = [];
      const groupedOptionsObject = {};
      for (const option of options) {
        if (!groupedOptionsObject[option[groupingField]]) groupedOptionsObject[option[groupingField]] = [];
        groupedOptionsObject[option[groupingField]].push(option);
      }
      // eslint-disable-next-line guard-for-in
      for (const key in groupedOptionsObject) {
        groupedOptionsArray.push(
          { [labelField]: key, type: 'section' },
          ...groupedOptionsObject[key],
        );
      }
      return groupedOptionsArray;
    }
    return options;
  }, [options]);

  const listItems = (props) => {
    const { data, index, style } = props;
    if (data?.[index]?.type === 'internalLink') {
      return (
        <ListItem
          disableGutters
          dense
          disablePadding
          divider={divider}
          style={style}
          key={index}
          component="div"
        >
          <ListItemIcon>
            {data?.[index]?.tooltip ? (
              <Tooltip
                title={data?.[index]?.tooltip?.title}
                placement={data?.[index]?.tooltip?.placement || 'top'}
              >
                {data?.[index]?.icon}
              </Tooltip>
            ) : (
              data?.[index]?.icon
            )}
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography
              noWrap
              component={Link}
              to={data?.[index]?.link}
            >
              <OverflowTooltip>
                {data?.[index]?.[labelField]}
              </OverflowTooltip>
            </Typography>
          </ListItemText>
        </ListItem>
      );
    }
    return (
      <ListItem
        disableGutters
        dense
        disablePadding
        divider={divider}
        style={style}
        key={index}
        component="div"
      >
        <ListItemIcon>
          {data?.[index]?.icon}
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography noWrap>
            <OverflowTooltip>
              {data?.[index]?.[labelField]}
            </OverflowTooltip>
          </Typography>
        </ListItemText>
      </ListItem>
    );
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <FixedSizeList
        itemData={optionsDisplay}
        height={height}
        width="100%"
        itemSize={50}
        itemCount={optionsDisplay?.length}
        overscanCount={5}
      >
        {listItems}
      </FixedSizeList>
    </Box>
  );
}
