import React, { useState, useRef } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import themes from "components/themes";
import { useOutsideClick } from "v2/hooks/useOutsideClick";
import { Badge, Box } from "@mui/material";
import { SavedLinksPanel } from "@upptic/module-ui";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";

// -- COMPONENT
function SavedLinks(): JSX.Element {
  const { t: tg } = useTranslation("general");
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  const menuRef = useRef(null);

  // disable outside click close for now
  useOutsideClick(menuRef, () => setMenuVisible(false));

  return (
    <Wrapper ref={menuRef}>
      <Badge
        color="primary"
        style={{ cursor: "pointer" }}
        onClick={() => setMenuVisible((val) => !val)}
      >
        <FavoriteIcon />
      </Badge>
      {menuVisible && (
        <MenuWrapper>
          <Box mb={1.5}>
            <SavedLinksPanel
              buttons={[
                {
                  icon: <CloseIcon />,
                  onClick: () => setMenuVisible(false),
                  tooltip: {
                    title: tg<string>("close"),
                  },
                },
              ]}
            />
          </Box>
        </MenuWrapper>
      )}
    </Wrapper>
  );
}

// -- STYLED
const Wrapper = styled.div`
  position: relative;
`;

const MenuWrapper = styled.div`
  max-height: calc(100vh - 130px);
  width: 600px;
  position: absolute;
  left: -14px;

  background-color: ${themes.colors.backgroundLight};

  transform: translate(-550px, 17px);

  border-radius: 8px;
`;

const SnackbarWrapper = styled.div`
  display: block;
  p,
  ul {
    margin-bottom: 0;
  }
  ul {
    padding-left: 15px;
  }
`;

export const SavedLinksElement = observer(SavedLinks);
