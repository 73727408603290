/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useContext } from 'react';
import {
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { PlatformContext } from '@upptic/module-directory';

export const InternalLinkElement = ({
  element,
  index,
}) => {
  const platformStore = useContext(PlatformContext);
  const clientCode = platformStore.currentClient?.code;
  const renderIcon = useMemo(() => {
    switch (element.icon) {
      case 'edit':
        return <EditIcon fontSize="1.3rem" />;
      default:
        return null;
    }
  }, [element?.icon]);

  const formattedLink = useMemo(() => {
    const link = element?.link;
    const linkReplace = link.replace(':clientCode', clientCode);
    return linkReplace;
  }, [element?.link, clientCode]);

  return (
    <Grid
      item
      xs={element.xs || 12}
      key={index}
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-end"
    >
      <Tooltip
        title={element?.text}
        placement="top"
      >
        <IconButton
          component={Link}
          to={formattedLink}
          sx={{
            height: '32px',
            width: '32px',
          }}
        >
          {renderIcon}
        </IconButton>
      </Tooltip>
    </Grid>
  );
};
