/* eslint-disable no-continue */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useContext } from 'react';
import { Box, CircularProgress, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { PlatformContext } from '@upptic/module-directory';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { sortBy } from 'lodash';

import { currentUserSavedLinksGql } from './gql';
import FormHeader from '../Forms/FormHeader';
import NonSelectionList from '../NonSelectionList';

const SavedLinksPanel = ({
  buttons,
  height = 300,
  renderHeader = true,
}) => {
  const { t } = useTranslation('platformDashboards');
  const { t: tg } = useTranslation('general');
  const platformStore = useContext(PlatformContext);

  const { data, loading } = useQuery(currentUserSavedLinksGql, {
    skip: !platformStore.currentClient?.code,
    fetchPolicy: 'no-cache',
    onError: () => {},
    context: { handleNetworkError: true },
    variables: {
      clientCode: platformStore.currentClient?.code,
      applicationCode: platformStore.selectedApplication?.code,
    },
  });

  const savedItems = useMemo(() => {
    const items = [];
    if (data && data?.currentUserSavedLinks?.length !== 0) {
      const sortedDataBySequenceId = sortBy(data?.currentUserSavedLinks, ['sequenceId']);
      for (const item of sortedDataBySequenceId) {
        if (item?.visibility === 'private') {
          items.push({
            ...item,
            icon: <VisibilityOffIcon />,
            tooltip: {
              title: t('privateLink'),
            },
            type: 'internalLink',
          });
          continue;
        }
        items.push({
          ...item,
          icon: <VisibilityIcon />,
          tooltip: {
            title: t('accountLink'),
          },
          type: 'internalLink',
        });
      }
    }
    return items;
  }, [data]);

  const buttonsToRender = useMemo(() => {
    const buttonArray = [];
    buttonArray.push({
      icon: <EditIcon />,
      to: `/${platformStore.currentClient?.code}/user/profile`,
      tooltip: {
        title: tg('edit'),
      },
    });
    if (buttons) buttons.forEach((button) => buttonArray.push(button));
    return buttonArray;
  }, [buttons]);

  return (
    <Box
      height="100%"
      width="100%"
      sx={{
        padding: '4px 16px',
      }}
    >
      {renderHeader ? (
        <>
          <FormHeader
            title={t('savedItems')}
            buttons={buttonsToRender}
            sx={{ height: 48 }}
          />
          <Divider />
        </>
      ) : null}
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={height}
        >
          <CircularProgress size="3rem" />
        </Box>
      ) : (
        <NonSelectionList
          options={savedItems}
          labelField="name"
          divider={false}
          height={height}
        />
      )}
    </Box>
  );
};

export default observer(SavedLinksPanel);
