/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import SavedLinksPanel from '../../SavedLinks/SavedLinksPanel';

const SavedLinksElement = () => (
  <SavedLinksPanel
    height={462}
    renderHeader={false}
  />
);

export default SavedLinksElement;
