import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import { Form } from 'react-final-form';
import FormSectionWFields from '../Forms/FormSectionWFields';
import { validators } from '../../utils';

const SaveLinkDialog = ({
  open,
  onClose,
  onSubmit,
  data,
  canCreateApplicationSharedLink,
  showSaveAsNewButton,
  onSaveNew,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('platformDashboards');
  const { t: tg } = useTranslation('general');

  const fields = [
    {
      name: 'name',
      label: t('fieldLabels.name'),
      xs: 12,
      validate: validators.required,
    },
    {
      name: 'visibility',
      label: t('fieldLabels.visibility'),
      type: 'select',
      options: [
        { label: t('fieldLabels.account'), value: 'account' },
        { label: t('fieldLabels.private'), value: 'private' },
      ],
      validate: validators.required,
      isDisabled: (!data && !canCreateApplicationSharedLink),
    },
    {
      name: 'sequenceId',
      label: t('fieldLabels.sequenceId'),
      type: 'number',
      validate: validators.required,
    },
  ];

  const initialValues = {
    visibility: 'private',
    name: '',
    sequenceId: 0,
    ...data,
  };

  const renderButtons = (submitting, pristine, values, hasVisibilityChanged) => {
    const buttons = [];
    buttons.push(
      <Button
        variant="contained"
        sx={{ minWidth: '130px' }}
        onClick={() => onClose()}
      >
        {tg('cancel')}
      </Button>,
    );
    if (showSaveAsNewButton && data && ((data?.visibility === 'account' && canCreateApplicationSharedLink) || data?.visibility === 'private')) {
      buttons.push(
        <Button
          variant="contained"
          sx={{ minWidth: '130px' }}
          onClick={() => onSaveNew(values)}
        >
          {t('saveAsNew')}
        </Button>,
      );
    }
    buttons.push(
      <Button
        variant="contained"
        sx={{ minWidth: '130px' }}
        type="submit"
        disabled={submitting || pristine || (data && hasVisibilityChanged)}
      >
        {data ? t('update') : tg('save')}
      </Button>,
    );
    return buttons;
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xl"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, pristine, values }) => {
          const hasVisibilityChanged = values?.visibility !== initialValues?.visibility;
          return (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Card
                sx={{ border: '1px solid' }}
              >
                <DialogTitle backgroundColor={theme.palette.secondary.main}>{t('saveLink')}</DialogTitle>
                <DialogContent sx={{ backgroundColor: theme.palette.secondary.main }}>
                  <FormSectionWFields fields={fields} />
                  {data && hasVisibilityChanged ? (
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      px={2}
                    >
                      <Typography>{t('visibilityCannotBeChanged')}</Typography>
                    </Box>
                  ) : null}
                  <Stack
                    sx={{ width: '100%' }}
                    spacing={2}
                    pt={2}
                    px={2}
                    direction="row"
                    justifyContent="flex-end"
                  >
                    {renderButtons(submitting, pristine, values, hasVisibilityChanged)}
                  </Stack>
                </DialogContent>
              </Card>
            </form>
          );
        }}
      </Form>
    </Dialog>
  );
};

export default SaveLinkDialog;
