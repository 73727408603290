import React from "react";
import { styled } from "@mui/system";
import { Flex, Box } from "@rebass/grid";
import { NavLink } from "react-router-dom";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";
import { Button, useMediaQuery } from "@mui/material";

import themes from "components/themes";

import logoImage from "assets/images/logo.svg";
import uppticLogoSmall from "assets/images/upptic-logo-small.svg";

import UserMenu from "./components/UserMenu";
import ContextsSwitcher from "v2/components/ContextsSwitcher/ContextsSwitcher";
import { NotificationBarElement } from "components/NotificationBarElement/NotificationBarElement";
import { UserPermissions } from "v2/api/types";
import { Icon, Icons } from "components";
import { useIsMobile } from "../SideMenuV2/helper";
import { SavedLinksElement } from "./components/SavedLinks";

// -- TYPES
type TopBarProps = {
  showFocusMode?: boolean;
  onMenuToggle: () => void;
  collapsed: boolean;
};

// -- COMPONENT
function TopBar({
  showFocusMode = false,
  collapsed,
  onMenuToggle,
}: TopBarProps) {
  const platformStore = React.useContext<PlatformStore>(PlatformContext);
  const canNavigateToHub = platformStore?.currentUserFrontendPermissions?.includes(
    UserPermissions.readHubOverview,
  );
  const clientCode = platformStore?.currentClient?.code;
  const lgDown = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
  const isMobile = useIsMobile();
  const isInfluencer = platformStore?.currentUserFrontendPermissions?.includes(
    "PSOSIPR",
  );
  if (showFocusMode) {
    return (
      <Wrapper>
        <Container>
          <Flex alignItems="center">
            <Box>
              <Logo />
            </Box>
          </Flex>
          <UserActionMenu>
            <Button
              href={`/sign-in?redirectUrl=${window.location.pathname}`}
              sx={{ marginRight: 2, color: "white" }}
            >
              Login
            </Button>
          </UserActionMenu>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Flex alignItems="center">
          {isMobile && (
            <Box>
              <MenuToggle onClick={onMenuToggle}>
                <Icon
                  icon={collapsed ? Icons.faBars : Icons.faArrowLeft}
                  color={themes.colors.white}
                  size="lg"
                />
              </MenuToggle>
            </Box>
          )}
          <Box>
            {canNavigateToHub ? (
              <NavLink to={`/${clientCode}/hub/overview`}>
                {lgDown && !isMobile ? <LogoSmall /> : <Logo />}
              </NavLink>
            ) : (
              <Logo />
            )}
          </Box>
        </Flex>
        <UserActionMenu>
          {isMobile || isInfluencer ? null : (
            <>
              <ContextsSwitcher />
              <NotificationBarElement />
            </>
          )}
          <SavedLinksElement />
          <UserMenu />
        </UserActionMenu>
      </Container>
    </Wrapper>
  );
}

// -- STYLES
const Wrapper = styled("div")`
  min-height: 60px;
`;

const Container = styled("header")`
  background-color: ${themes.colors.inputs};

  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${themes.colors.lightGrey};

  position: fixed;
  top: 0;
  z-index: 1201;
  width: 100%;
`;

const Logo = styled("div")`
  height: 28px;
  width: 120px;
  margin-left: 28px;
  background-image: url(${logoImage});
  background-repeat: no-repeat;
  background-size: contain;
`;

const LogoSmall = styled("div")`
  height: 40px;
  width: 40px;
  margin-left: 28px;
  background-image: url(${uppticLogoSmall});
  background-repeat: no-repeat;
  background-size: contain;
  &:hover {
    cursor: pointer;
  }
`;

const UserActionMenu = styled("div")`
  display: flex;

  justify-content: inherit;
  align-items: inherit;

  > *:not(:last-child) {
    margin-right: 30px;
  }
`;

const MenuToggle = styled(Flex)`
  height: 60px;
  width: 80px;
  user-select: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: ${themes.colors.background};
`;

export default TopBar;
