/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ScoreCard from './ScoreCard';
import GroupCard from './GroupCard';
import ActionItemElement from './GroupCardElements/ActionItemElement';
import NotificationElement from './GroupCardElements/NotificationElement';
import DiscordCard from './DiscordCard';
import PlaceholderCard from './PlaceholderCard';
import TextPlaceholderCard from './TextPlaceholderCard';
import CalendarCard from './CalendarCard';
import SavedLinksElement from './SavedLinksElements/SavedLinksElement';

export default function WidgetRenderer({ widget, appReportingCurrency, paybackWindow, modelType }) {
  switch (widget.type) {
    case 'scoreCard':
      return (
        <ScoreCard
          widget={widget}
          appReportingCurrency={appReportingCurrency}
          paybackWindow={paybackWindow}
          modelType={modelType}
        />
      );
    case 'discordCard':
      return (<DiscordCard widget={widget} />);
    case 'group':
      return (<GroupCard widget={widget} WidgetRenderer={WidgetRenderer} />);
    case 'notifications':
      return (<NotificationElement widget={widget} />);
    case 'actionItems':
      return (<ActionItemElement widget={widget} />);
    case 'myActionItem':
      return (<ActionItemElement widget={widget} />);
    case 'calendar':
      return (<CalendarCard widget={widget} />);
    case 'placeholderLink':
      return (<PlaceholderCard widget={widget} />);
    case 'textPlaceholder':
      return (<TextPlaceholderCard widget={widget} />);
    case 'savedLinks':
      return (<SavedLinksElement widget={widget} />);
    default:
      return (<p>{widget.type}</p>);
  }
}
