import gql from 'graphql-tag';

export const currentUserSavedLinksGql = gql`
  query currentUserSavedLinks($clientCode: String!, $applicationCode: String!) {
    currentUserSavedLinks(
      clientCode: $clientCode
      applicationCode: $applicationCode
    ) {
      code
      name
      link
      status
      visibility
      sequenceId
    }
  }
`;
