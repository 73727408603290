import React from 'react';
import {
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ModelSwitcherElement } from '../ScoreCardElements/ModelSwitcherElement';
import { InternalLinkElement } from './InternalLinkElement';

export const HeaderElements = ({
  widget,
  modelType,
  setModelType,
  isIos,
}) => {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const headerElements = widget?.params?.headerElements;

  const renderElements = (element, index) => {
    if (element.type === 'text') {
      return (
        <Grid
          item
          xs={element.xs || 12}
          key={index}
        >
          <Typography
            variant={!smDown ? 'h6' : 'subtitle1'}
            noWrap
            align={!smDown ? element.align : 'left'}
            mr={1}
          >
            {`${element?.title}: ${element?.value}`}
          </Typography>
        </Grid>
      );
    }
    if (element.type === 'modelSwitcher') {
      return (
        <ModelSwitcherElement
          element={element}
          index={index}
          modelType={modelType}
          setModelType={setModelType}
          isIos={isIos}
        />
      );
    }
    if (element.type === 'internalLink') {
      return (
        <InternalLinkElement
          element={element}
          index={index}
        />
      );
    }
    return null;
  };
  return (
    <Grid
      item
      xs={!smDown ? 6 : 12}
    >
      <Grid
        container
        spacing={1}
        height={56}
      >
        {headerElements?.map(renderElements)}
      </Grid>
    </Grid>
  );
};
